import React from 'react';
import SEO from '../../components/SEO';
import Layout from '../../layouts/index';
import Call from '../../components/Call';
import ReactJotformEmbed from 'react-jotform-embed';

const Contact = (props) => (
  <Layout bodyClass="page-contact contact-page">
    <SEO
      title="HVAC Maintinence Heating, Furnace, AC or Ductwork Intallation, Repair, or Service"
      description="Schedule your HVAC Maintinence Here"
    />
    <div className="container22">
      <div className="container22 pt-4 pb-4">
        <h1>Contact LaSalle</h1>
      </div>
      <p>Get started scheduling your service using the form below:</p>
      <div className="form-block">
        <ReactJotformEmbed src="https://form.jotform.com/211227431380041" />
      </div>
      <Call button={false} />
    </div>
  </Layout>
);

export default Contact;
